::selection {
    color: rgb(162 28 175);
    background: rgb(245 208 254);
}

/* .noSelect {
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
} */

.lazyload,
.lazyloading {
    background-color: #f3f3f3;
}

.lazyloaded {
    background-color: #f3f3f3;
}

.animation-fade-up{
    animation: fadeInUp 0.25s ease-in;
}

@keyframes fadeInUp{
    from{
        opacity: 0;
        transform: translateY(100%);
    }
    to{
        opacity: 1;
        transform: translateY(0);
    }
}

@media (min-width: 2200px) {
    .header3xl {
        width: 75%;
    }
}
