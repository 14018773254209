html::-webkit-scrollbar {
  width: 15px;
}

html::-webkit-scrollbar-track {
  background-color: transparent;
}

html::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background-color: rgb(156 163 175);
  border: 4px solid white;
}