body {
    background: linear-gradient(
        to bottom left,
        rgb(240, 229, 251),
        rgb(255, 255, 255),
        rgb(255 247 237)
    );
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
    padding: 0;
    margin: 0;
}
