.markdown h3 {
    font-size: 22px;
    font-weight: 700;
    color: rgb(15 23 42);
    margin-top: 50px;
}

.markdown code span{
    white-space: pre;
}

.markdown hr {
    height: 1px;
    border: none;
    background-color: #dadada;
    margin: 50px 0;
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    box-sizing: content-box;
}

@font-face {
    font-family: sourcecodepro;
    src: url("https://res.cloudinary.com/djvyifjgz/raw/upload/v1683364919/SourceCodePro-Regular_txezup.ttf");
    font-display: swap;
}

.sourcecodepro {
    font-family: sourcecodepro;
}

.markdown em {
    font-family: sourcecodepro;
    font-size: 14px;
    font-style: normal;
    color: black;
    background-color: rgb(250 232 255) !important;
    padding: 5px 3px;
    border-radius: 6px;
}

.markdown em::after,
.markdown em::before {
    content: "'";
}

.markdown ol  .markdown ul {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.markdown ol li, .markdown ul li {
    list-style-type: decimal;
    margin-left: 40px;
}

.markdown-work p {
    margin: 0 0 40px 0;
}

.markdown ol {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    gap: 10px;
}

.markdown ol li {
    list-style-type: decimal;
    margin-left: 40px;
    margin-top: 0;
    margin-bottom: 0;
}