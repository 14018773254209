.background-blur {
    border: 9999px;
}

.background-blur-absolute {
    position: absolute;
}

.background-blur-red {
    background-color: rgb(37 99 235);
    top: -50px;
    left: -50px;
}

.background-blur-blue {
    background-color: rgb(129 140 248);
    right: 0;
    bottom: 0;
}

@keyframes slideInFromRight {
    0% {
        transform: translateX(100%);
        opacity: 0;
    }
    100% {
        transform: translateX(0);
        opacity: 1;
    }
}
@keyframes slideOutToRight {
    0% {
        transform: translateX(0);
        opacity: 1;
    }
    100% {
        transform: translateX(100%);
        opacity: 0;
    }
}
.animate-slideInFromRight {
    animation: slideInFromRight 0.3s ease-out forwards;
}
.animate-slideOutToRight {
    animation: slideOutToRight 0.3s ease-in forwards;
}
