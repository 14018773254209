.navbar {
    opacity: 1;
    transition: opacity 0.5s ease-in-out;
}

.user-bar {
    opacity: 1;
    transition: opacity 0.5s ease-in-out;
}

/* 
    Loading 
*/

.loader-line {
    width: 200px;
    height: 5px;
    position: relative;
    overflow: hidden;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    border-radius: 20px;
}

.loader-line:before {
    content: "";
    position: absolute;
    left: -50%;
    height: 5px;
    width: 40%;
    -webkit-animation: lineAnim 1s linear infinite;
    -moz-animation: lineAnim 1s linear infinite;
    animation: lineAnim 1s linear infinite;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    border-radius: 20px;
}

@keyframes lineAnim {
    0% {
        left: -40%;
    }

    50% {
        left: 20%;
        width: 80%;
    }

    100% {
        left: 100%;
        width: 100%;
    }
}

.notch::before,
.notch::after {
    content: "";
    position: absolute;
    top: -1px;
    width: 13px;
    height: 7px;
    background-size: 50% 100%;
    background-repeat: no-repeat;
    background-image: radial-gradient(
        circle at 0 100%,
        transparent 6px,
        black 7px
    );
}

.notch::before {
    left: -6px;
}

.notch::after {
    left: 99.9%;
    background-image: radial-gradient(
        circle at 100% 100%,
        transparent 6px,
        black 7px
    );
}
.notchExpand {
    animation: notchExpand 0.5s ease-in-out forwards;
}

.notchCollapse {
    animation: notchCollapse 0.5s ease-in-out forwards;
}

@keyframes notchExpand {
    0% {
        height: 10px;
    }
    100% {
        height: 42px;
    }
}

@keyframes notchCollapse {
    0% {
        height: 42px;
    }
    100% {
        height: 10px;
    }
}

.fadeIn {
    animation: fadeIn 0.5s ease-in-out forwards;
}

.fadeOut {
    animation: fadeOut 0.5s ease-in-out forwards;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
        transform: translateY(-20px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes fadeOut {
    0% {
        opacity: 1;
        transform: translateY(0);
    }
    100% {
        opacity: 0;
        transform: translateY(-20px);
    }
}